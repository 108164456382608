// extracted by mini-css-extract-plugin
export var header1 = "Navbar-module--header1--cb63c";
export var header2 = "Navbar-module--header2--4925c";
export var header3 = "Navbar-module--header3--cd3d9";
export var header4 = "Navbar-module--header4--f68e1";
export var header5 = "Navbar-module--header5--46a38";
export var inactive = "Navbar-module--inactive--5f3ba";
export var indented = "Navbar-module--indented--0c06e";
export var menu = "Navbar-module--menu--ee018";
export var menuBtn = "Navbar-module--menu-btn--f7109";
export var menuBtnLine = "Navbar-module--menu-btn-line--72f59";
export var mobileClosed = "Navbar-module--mobile-closed--93ad0";
export var mobileMenu = "Navbar-module--mobileMenu--d6aaa";
export var mobileMenuInner = "Navbar-module--mobileMenu-inner--4efae";
export var mobileOpen = "Navbar-module--mobile-open--5b20e";
export var navbar = "Navbar-module--navbar--b01a1";
export var navbarInner = "Navbar-module--navbar-inner--cd8cc";
export var navbarItem = "Navbar-module--navbar-item--71a4d";
export var navbarItemActive = "Navbar-module--navbar-item-active--4ba59";
export var open = "Navbar-module--open--9cf7e";
export var skipContent = "Navbar-module--skip-content--3bcae";