/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import "../../../styles/global.scss"

import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import { Header } from "../Header"

import * as styles from "./Layout.module.scss"

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const isproject = useMemo(() => {
    const pathArray = location.pathname.substring(1).split("/")

    if (pathArray[0] === "projects" && pathArray[1]?.length > 0) {
      return true
    } else return false
  }, [location])

  return (
    <div className={classNames({ [styles.greyBackground]: isproject })}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main id="main" className={styles.main}>
        {children}
      </main>
      <footer></footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
