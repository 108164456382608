import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import * as styles from "./Container.module.scss"

const Container = ({
  tagName: El = "div",
  children,
  className,
  innerClassName,
  border = false,
  padded = false,
  wrapped = false,
  recent = false,
  ...props
}) => {
  const cls = classNames(
    styles.container,
    { [styles.border]: border },
    { [styles.padded]: padded },
    { [styles.recent]: recent }
  )

  if (wrapped) {
    return (
      <El className={classNames(cls, styles.wrapped, className)} {...props}>
        <div className={classNames(styles.inner, innerClassName)}>
          {children}
        </div>
      </El>
    )
  }

  return (
    <El className={classNames(cls, className)} {...props}>
      {children}
    </El>
  )
}

Container.propTypes = {
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  tagName: PropTypes.string,
  wrapped: PropTypes.bool,
  border: PropTypes.bool,
  padded: PropTypes.bool,
}

export { Container }
