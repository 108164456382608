import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Navbar } from "./../Navbar"
import * as styles from "./Header.module.scss"

const Header = ({ siteTitle }) => {
  // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
  if (typeof window !== "undefined") {
    let prevScrollpos = window.pageYOffset
    window.onscroll = function () {
      const maxScroll = document.body.clientHeight - window.innerHeight
      let currentScrollPos = window.pageYOffset
      if (
        (maxScroll > 0 &&
          prevScrollpos > currentScrollPos &&
          prevScrollpos <= maxScroll) ||
        (maxScroll <= 0 && prevScrollpos > currentScrollPos) ||
        (prevScrollpos <= 0 && currentScrollPos <= 0)
      ) {
        document.getElementById("noshow-header").style.top = "0"
        document.getElementById("noshow-header").style.mixBlendMode = "normal"
      } else {
        document.getElementById("noshow-header").style.top = "-3.0rem" // adjustable based your need
        document.getElementById("noshow-header").style.mixBlendMode =
          "soft-light"
      }
      prevScrollpos = currentScrollPos
    }
  }

  return (
    <header id="noshow-header" className={classNames(styles.header)}>
      <Navbar />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export { Header }
