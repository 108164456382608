// extracted by mini-css-extract-plugin
export var border = "Container-module--border--34d1d";
export var container = "Container-module--container--9f434";
export var header1 = "Container-module--header1--6735e";
export var header2 = "Container-module--header2--1617c";
export var header3 = "Container-module--header3--2852b";
export var header4 = "Container-module--header4--6a69e";
export var header5 = "Container-module--header5--dd37c";
export var inner = "Container-module--inner--39fff";
export var padded = "Container-module--padded--cc22a";
export var recent = "Container-module--recent--29e82";
export var wrapped = "Container-module--wrapped--b6bba";