/* eslint-disable no-unused-expressions */
import React, { useState, createContext, useEffect } from "react"

const defaultValues = {
  currentArchiveTab: "all",
  year: "all",
  artist: "all",
  onOff: false,
  viewBy: "image",
  isOpen: false,
}

export const AVCOContext = createContext([defaultValues])

const AVCOProvider = ({ children }) => {
  const [currentArchiveTab, setCurrentArchiveTab] = useState(
    defaultValues.currentArchiveTab
  )
  const [year, setYear] = useState(defaultValues.year)
  const [artist, setArtist] = useState(defaultValues.artist)
  const [onOff, setOnOff] = useState(defaultValues.onOff)
  const [activeMenuItem, setActiveMenuItem] = useState("avco")
  const [historyChanged, setHistoryChanged] = useState(false)
  const [viewBy, setViewBy] = useState(defaultValues.viewBy)
  const [isOpen, setIsOpen] = useState(defaultValues.isOpen)

  const updateYear = value => setYear(value)
  const updateArtist = value => setArtist(value)
  const updateCurrentArchiveTab = value => setCurrentArchiveTab(value)
  const toggleOnOff = value => setOnOff(!onOff)

  useEffect(() => {
    document.body?.classList?.remove(...document.body.classList)
    document.body?.classList?.add(onOff ? "on" : "off")
  }, [onOff])

  return (
    <AVCOContext.Provider
      value={{
        currentArchiveTab,
        year,
        artist,
        onOff,
        activeMenuItem,
        viewBy,
        isOpen,
        setViewBy,
        setCurrentArchiveTab,
        setActiveMenuItem,
        updateYear,
        updateArtist,
        updateCurrentArchiveTab,
        toggleOnOff,
        setIsOpen,
        historyChanged,
        setHistoryChanged,
      }}
    >
      {children}
    </AVCOContext.Provider>
  )
}

export { AVCOProvider }
