/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"

import { AVCOProvider } from "./src/components/context/AVCOContext"
import { Layout } from "./src/components/layout/Layout"

export const wrapPageElement = ({ element, props }) => {
  return <Layout location={props.location}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => (
  <AVCOProvider> {element} </AVCOProvider>
)

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    "This application has been updated. Reload to display the latest version?"
  )
  if (answer === true) {
    window.location.reload()
  }
}
