import React, { useContext, useRef, useEffect } from "react"
import { Link } from "gatsby"
import { Container } from "../Container"
import classNames from "classnames"
import { AVCOContext } from "../../context/AVCOContext"

import * as styles from "./Navbar.module.scss"

const Navbar = () => {
  const mobileNavNode = useRef()

  const {
    activeMenuItem,
    setActiveMenuItem,
    isOpen,
    setIsOpen,
    setHistoryChanged,
  } = useContext(AVCOContext)

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    setIsOpen(!isOpen)
  }

  const handleClick = e => {
    if (mobileNavNode.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setIsOpen(false)
  }

  const handleKeyboardDown = e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault()
      e.target.click()
    }
  }

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  })

  useEffect(() => {
    setActiveMenuItem("avco")
  }, [activeMenuItem, setActiveMenuItem])

  return (
    <Container className={styles.menu}>
      <nav
        className={classNames(styles.navbar)}
        id="navbar"
        role="navigation"
        aria-label="main-navigation"
      >
        <a className={styles.skipContent} href="#main">
          Skip to content
        </a>
        <div className="container">
          <div id="mobile-menu" ref={mobileNavNode}>
            <div
              data-target="navMenu"
              onClick={toggleHamburger}
              onKeyDown={toggleHamburger}
              role="button"
              tabIndex="0"
              name="No Show Menu"
              title="No Show Menu"
              aria-label="Mobile menu"
              className={`${styles.menuBtn} ${isOpen && styles.open}`}
            >
              <div role="button">
                <span className={styles.menuBtnLine} />
                <span className={styles.menuBtnLine} />
                <span className={styles.menuBtnLine} />
                <span className={styles.menuBtnLine} />
              </div>
            </div>
          </div>
          <div
            id="navMobile"
            className={`${styles.mobileMenu} ${
              isOpen ? styles.mobileOpen : styles.mobileClosed
            }`}
          >
            <div className={styles.mobileMenuInner}>
              <div>
                <Link
                  className={classNames(styles.navbarItem)}
                  to="/"
                  onClick={() => {
                    setActiveMenuItem("avco")
                    setIsOpen(false)
                  }}
                  onKeyDown={() => {
                    setActiveMenuItem("avco")
                    setIsOpen(false)
                  }}
                >
                  AVCO
                </Link>
              </div>
              <div>
                <div>
                  <Link
                    className={classNames(styles.navbarItem)}
                    to="/projects"
                    onClick={() => {
                      setActiveMenuItem("projects")
                      setHistoryChanged(true)
                      setIsOpen(false)
                    }}
                    onKeyDown={() => {
                      setActiveMenuItem("projects")
                      setIsOpen(false)
                    }}
                  >
                    PROJECTS
                  </Link>
                </div>
                <div>
                  <div>
                    <Link
                      className={classNames(styles.navbarItem)}
                      to="/studio"
                      onClick={() => {
                        setActiveMenuItem("studio")
                        setIsOpen(false)
                      }}
                      onKeyDown={() => {
                        setActiveMenuItem("studio")
                        setIsOpen(false)
                      }}
                    >
                      STUDIO
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Desktop Menu */}
          <div id="navMenu" className={`navbar-menu ${isOpen ? "" : "active"}`}>
            <div className={`navbar-start ${styles.navbarInner}`}>
              <Link
                className={classNames(styles.navbarItem, {
                  [styles.navbarItemActive]: activeMenuItem === "avco",
                })}
                to="/"
                onClick={() => setActiveMenuItem("avco")}
                onKeyDown={handleKeyboardDown}
              >
                AVCO
              </Link>
              <Link
                className={classNames(styles.navbarItem, {
                  [styles.navbarItemActive]: activeMenuItem === "projects",
                })}
                to="/projects"
                onClick={() => {
                  setActiveMenuItem("projects")
                  setHistoryChanged(true)
                }}
                onKeyDown={handleKeyboardDown}
              >
                PROJECTS
              </Link>
              <Link
                className={classNames(styles.navbarItem, {
                  [styles.navbarItemActive]: activeMenuItem === "studio",
                })}
                to="/studio"
                onClick={() => {
                  setActiveMenuItem("studio")
                }}
                onKeyDown={handleKeyboardDown}
              >
                STUDIO
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </Container>
  )
}

export { Navbar }
